import { t, Trans } from "@lingui/macro";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Link as MuiLink, TextField, Typography } from "@mui/material";
import { useSessionStorageValue } from "@react-hookz/web";
import { CountryCode, parsePhoneNumberFromString } from "libphonenumber-js";
import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import invariant from "tiny-invariant";

import { useDateFormatter } from "../datetime/use-date-formatter";
import { DialogBottom } from "../dialog-bottom";
import { ErrorComponent } from "../error";
import { useGuessCurrentCountryCode } from "../hooks";
import { ReactComponent as ArrowLeft } from "../icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../icons/arrow-right.svg";
import { NewAppointmentLayout } from "../new-appointment-layout";
import { useBackgroundLocation } from "../router/background-location-context";
import { colors, theme } from "../theme";
import { useNewAppointmentAndUpdatePatientMutation } from "./create-appointment-and-update-patient.graphql";
import { useGetTimeslotAndCurrentPatientQuery } from "./get-timeslot-and-current-patient.graphql";

interface FormValues {
  email: string;
  phoneNumber: string;
}
interface PersistData {
  reason?: string;
}
function ConfirmationNewAppointmentDialog() {
  const backgroundLocation = useBackgroundLocation();
  const { formatDateTime } = useDateFormatter();
  const countryCode = useGuessCurrentCountryCode();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParameters] = useSearchParams();

  const timeslotId = searchParameters.get("timeslotId");
  invariant(timeslotId, "timeslot Id is not provided");
  const shortlinkIdent = searchParameters.get("link");

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(true);

  const sessionStorageValue = useSessionStorageValue<PersistData>(
    "patient-information",
  );
  const reason = sessionStorageValue.value?.reason ?? "";

  const {
    data: { timeslot: selectedTimeslot, patient } = {},
    loading,
    error,
  } = useGetTimeslotAndCurrentPatientQuery({
    variables: { timeslotId },
    fetchPolicy: "network-only",
  });

  const [
    createAppointmentAndUpdatePatient,
    { loading: mutationLoading, error: mutationError },
  ] = useNewAppointmentAndUpdatePatientMutation();

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<FormValues>({ mode: "onChange" });

  if ((loading && !timeslotId && !patient) || (patient && !patient.email)) {
    return null;
  }

  if (mutationError) {
    return (
      <NewAppointmentLayout>
        <ErrorComponent component="new-booking-error" />
      </NewAppointmentLayout>
    );
  }

  if (error || !patient || !timeslotId || !reason) {
    return (
      <NewAppointmentLayout>
        <ErrorComponent component="new-booking-error" />
      </NewAppointmentLayout>
    );
  }

  const onSubmit: SubmitHandler<FormValues> = async ({
    email,

    phoneNumber: formattedPhoneNumber = "",
  }) => {
    //If empty string is passed, it will be converted to undefined

    const phoneNumber =
      parsePhoneNumberFromString(formattedPhoneNumber)?.number ?? undefined;

    try {
      const { data: mutationData } = await createAppointmentAndUpdatePatient({
        variables: {
          timeslotId,
          reason,
          patientId: patient.id,
          patient: {
            email,
            phoneNumber,
          },
          shortlinkIdent,
        },
      });
      navigate(
        {
          pathname: `../status/${mutationData?.createAppointment?.createAppointmentRequest?.id}`,
          search: backgroundLocation.search,
        },
        { replace: true },
      );
    } catch (caughtError) {
      console.error(caughtError);
      // but ignore, status page will show error
    }
  };

  function handleDialogClose() {
    setIsDialogOpen(false);
  }

  if (!patient && loading) {
    return null;
  }

  return (
    <DialogBottom
      open={isDialogOpen}
      onClose={handleDialogClose}
      onExited={() => {
        navigate(
          { pathname: backgroundLocation.pathname, search: location.search },
          {
            replace: true,
          },
        );
      }}
    >
      <Box
        sx={{
          paddingTop: 2,
          paddingX: 2,
          paddingBottom: 1,
          display: "inline-flex",
          color: colors.blue,
          textAlign: "center",
          alignItems: "center",
          fontWeight: 600,
          fontSize: "18px",
        }}
      >
        {theme.direction === "ltr" ? (
          <ArrowBackIos fontSize="small" />
        ) : (
          <ArrowForwardIos fontSize="small" />
        )}
        <MuiLink
          onClick={() => navigate(-1)}
          underline="none"
          color={colors.blue}
        >
          <Trans>Back</Trans>
        </MuiLink>
      </Box>
      <Box
        sx={{
          textAlign: "center",
          paddingX: {
            xs: 2,
            sm: 3,
          },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Box
            sx={{
              padding: {
                xs: 1,
                sm: 2,
              },
            }}
          >
            <Typography variant="h4" align="center">
              <Trans>Confirmation will be sent to</Trans>
            </Typography>
            <Typography fontSize="17px" sx={{ opacity: 0.6 }} marginY={1}>
              <Trans>Please confirm your contact details</Trans>
            </Typography>
            <Box sx={{ marginBottom: "17px" }}>
              <Controller
                render={({ field, fieldState }) => {
                  return (
                    <MuiTelInput
                      sx={{ direction: "ltr" }}
                      {...field}
                      fullWidth
                      label={t`Mobile phone`}
                      helperText={errors.phoneNumber?.message}
                      error={Boolean(fieldState.error)}
                      preferredCountries={[
                        ...(countryCode ? [countryCode as CountryCode] : []),
                        "SE",
                      ]}
                      defaultCountry={(countryCode as CountryCode) || "SE"}
                      forceCallingCode
                    />
                  );
                }}
                defaultValue={patient.phoneNumber ?? ""}
                name="phoneNumber"
                control={control}
                rules={{
                  required: t`Phone number is required`,
                  validate(value) {
                    if (matchIsValidTel(value)) {
                      return true;
                    }

                    return t`Please provide the phone number in a valid format, example: +46701234567`;
                  },
                }}
              />
              <Controller
                name="email"
                control={control}
                defaultValue={patient.email ?? ""}
                rules={{
                  required: t`Email is required.`,
                  pattern: {
                    value: /^.+@.+$/,
                    message: t`Please provide a valid email address.`,
                  },
                }}
                render={({ field }) => (
                  <TextField
                    sx={{ direction: "ltr" }}
                    {...field}
                    label={t`Email`}
                    size="medium"
                    fullWidth={true}
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message}
                    onChange={(event) => {
                      field.onChange(event.target.value);
                    }}
                    type="email"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Box>
            {selectedTimeslot ? (
              <Typography
                fontSize="21px"
                fontWeight={700}
                align="center"
                color="secondary"
                sx={{
                  ":first-letter": {
                    textTransform: "uppercase",
                  },
                }}
              >
                {formatDateTime(
                  new Date(selectedTimeslot.startAtInCareUnitsTimezone),
                )}
              </Typography>
            ) : undefined}

            <LoadingButton
              type="submit"
              variant="contained"
              size="large"
              fullWidth
              disabled={isSubmitting || mutationLoading}
              endIcon={
                theme.direction === "ltr" ? (
                  <ArrowRight style={{ fill: "currentColor" }} />
                ) : (
                  <ArrowLeft style={{ fill: "currentcolor" }} />
                )
              }
              loading={isSubmitting || mutationLoading}
              loadingPosition="end"
            >
              <Trans>Confirm</Trans>
            </LoadingButton>
          </Box>
        </form>
      </Box>
    </DialogBottom>
  );
}

export { ConfirmationNewAppointmentDialog };
