import { Trans } from "@lingui/macro";
import CloseIcon from "@mui/icons-material/Close";
import { Avatar, Box, Button, Card, Typography } from "@mui/material";
import { isToday, isTomorrow } from "date-fns";
import { useNavigate } from "react-router-dom";

import { trackEvent } from "../../analytics";
import { useDateFormatter } from "../../datetime/use-date-formatter";
import { ErrorComponent } from "../../error";
import { ReactComponent as ArrowRight } from "../../icons/arrow-right.svg";
import { colors } from "../../theme";
import { GetOffersQuery } from "./get-offers.graphql";
import { useUpdateOfferStatusMutation } from "./update-offer.graphql";

function OfferDetails({
  offer,
}: {
  offer: NonNullable<GetOffersQuery["offers"]>[number];
}) {
  const { formatDate, formatTime } = useDateFormatter();
  const navigate = useNavigate();

  const [updateOffer, { loading: mutationLoading, error: mutationError }] =
    useUpdateOfferStatusMutation();

  const handleDeclineOffer = async () => {
    await updateOffer({
      variables: {
        id: offer.id,
        status: "DECLINED",
      },
    });
    trackEvent("decline-offer", {
      ...(offer.timeslot?.careUnit?.id
        ? {
            props: {
              careUnitId: offer.timeslot.careUnit.id,
            },
          }
        : {}),
    });
    navigate("/");
  };

  const handleAcceptOffer = async () => {
    const { data, errors } = await updateOffer({
      variables: {
        id: offer.id,
        status: "ACCEPTED",
      },
    });

    if (errors || !offer) {
      return <ErrorComponent />;
    }

    if (data?.updateOffer?.offer?.status === "ACCEPTED") {
      const updatedOffer = data.updateOffer.offer;

      navigate(
        `/appointment/${updatedOffer.appointmentId}/reschedule/${updatedOffer.timeslotId}/status/${updatedOffer.rescheduleAppointmentRequestId}`,
      );

      trackEvent("accept-offer", {
        ...(offer.timeslot?.careUnit?.id
          ? {
              props: {
                careUnitId: offer.timeslot.careUnit.id,
              },
            }
          : {}),
      });
    }
  };

  if (mutationError) {
    return <ErrorComponent />;
  }

  return (
    <Box
      sx={{
        padding: {
          xs: 2,
          sm: 3,
        },
      }}
    >
      <Card
        sx={{
          border: "2px solid rgba(0, 0, 0, 0.1)",
          backgroundColor: colors.white,
          boxShadow: "0px 16px 16px -11px rgba(0, 0, 0, 0.05)",
          borderRadius: "13px",
          overflow: "visible",
          color: colors.zymegoGreen,
        }}
      >
        <Box
          sx={{
            padding: {
              xs: 2,
              sm: 3,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              direction: "flex-inline",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <Typography fontSize="23px" component="div" fontWeight="500">
              {offer.timeslot?.careUnit?.name}
            </Typography>
          </Box>

          <Typography variant="subtitle1" component="div" fontWeight="700">
            <Trans>Appointment with {offer.timeslot?.caregiver?.name}</Trans>
          </Typography>
        </Box>
        {offer.timeslot ? (
          <Box
            sx={{
              width: "calc(100% + 24px)",
              marginLeft: "-12px",
              backgroundColor: colors.white,
              border: "2px solid rgba(0, 165, 164, 0.75)",
              borderRadius: "12px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "13px 20px",
              }}
            >
              <Typography
                component="div"
                sx={{
                  fontSize: "30px",
                  fontWeight: 500,
                  marginRight: "14px",
                }}
              >
                {isToday(new Date(offer.timeslot.startAt)) ? (
                  <Trans>Today</Trans>
                ) : isTomorrow(new Date(offer.timeslot.startAt)) ? (
                  <Trans>Tomorrow</Trans>
                ) : (
                  formatDate(new Date(offer.timeslot.startAt))
                )}
              </Typography>
              <Box>
                <Typography component="div" variant="info" fontWeight="700">
                  {formatTime(new Date(offer.timeslot.startAt))}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                padding: "10px 20px",
                backgroundColor: "rgba(158, 221, 220, 0.3)",
              }}
            >
              {offer.appointment ? (
                <Typography variant="subtitle1">
                  <Trans>
                    Replacing appointment{" "}
                    <strong>
                      {formatDate(
                        new Date(offer.appointment.startAtInCareUnitsTimezone),
                      )}
                    </strong>{" "}
                    at{" "}
                    {formatTime(
                      new Date(offer.appointment.endAtInCareUnitsTimezone),
                    )}
                  </Trans>
                </Typography>
              ) : null}
            </Box>
          </Box>
        ) : null}

        <Box
          sx={{
            padding: {
              xs: 2,
              sm: 3,
            },
            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={mutationLoading}
            fullWidth
            endIcon={<ArrowRight style={{ fill: "currentColor" }} />}
            onClick={handleAcceptOffer}
            sx={{ padding: { xs: 1, sm: 1.5 } }}
          >
            <Trans>Accept offer</Trans>
          </Button>
        </Box>
        <Button
          variant="dialog"
          color="info"
          startIcon={
            <Avatar
              sx={{
                width: "30px",
                height: "30px",
                marginRight: "8px",
                color: colors.red,
                backgroundColor: colors.zymegoPowder,
              }}
            >
              <CloseIcon />
            </Avatar>
          }
          disabled={mutationLoading}
          fullWidth
          onClick={handleDeclineOffer}
        >
          <Trans>Decline</Trans>
        </Button>
      </Card>
    </Box>
  );
}

export { OfferDetails };
