mutation NewAppointmentAndUpdatePatient(
  $timeslotId: UUID!
  $patientId: UUID!
  $patient: PatientPatch!
  $reason: String!
  $shortlinkIdent: String
) {
  createAppointment(
    input: {
      timeslotId: $timeslotId
      reason: $reason
      shortlinkIdent: $shortlinkIdent
    }
  ) {
    createAppointmentRequest {
      id
      status
    }
  }

  updatePatient(input: { id: $patientId, patch: $patient }) {
    patient {
      email
      id
      phoneNumber
    }
  }
}
